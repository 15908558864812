import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import junkremoval from "../images/junk-removal.jpg";

function JunkRemoval() {
  return (
    <>
      <NavBar
        title="Junk Removal | Point Blank Moving"
        description="Lee's Summit Movers, the best local moving company in Lee Summit. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Lee's Summit Missouri. The best junk removal company in Kansas City Missouri. Point blank junk removal."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${junkremoval})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Kansas City Junk Removal
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div>
        <div className="content">
          <div className="content--loc--header">
            <h2
              style={{
                fontSize: "30px",
                marginTop: 20,
                marginBottom: 10,
                padding: 10,
                textAlign: "center",
              }}
            >
              Reliable Junk Removal in Kansas City
            </h2>
            <div>
              Need to get rid of some things you would rather not move into your
              new home? Spring cleaning the basement for the first time since
              the kids moved out? Either way Point Blank Moving has you covered
              with a range of options. We can take just one piece of junk up to
              a full load. We have flat rate and hourly options and can include
              discounts if you have already booked the day of your junk removal!
            </div>
            <div>
              Point Blank Moving is your local junk removal solution! Operating
              in the Greater Kansas City area, we are ready and able to help
              reclaim space in your home or commercial space! As a full-service
              junk removal company, we are committed to helping our residential
              and commercial clients haul all types of junk off their
              properties. We are typically in your neighborhood and always happy
              to help. Our awesome team members can do all the heavy lifting as
              we are trained movers - so you don’t have to lift a finger. Just
              point, and we make the junk disappear!
            </div>
            <div>
              Point Blank offers the most professional level of service in the
              junk removal industry, and we guarantee to meet or beat any
              written estimate.
            </div>
          </div>
          <div className="content--loc--generic">
            <h2 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
              Need Residential Junk Removal Services?
            </h2>
            <div>
              Our residential junk removal services provide an easy solution for
              clearing out clutter and hauling away unwanted junk. Many of our
              moving customers, hire us for these services as they don’t want to
              move everything with them to their new location.
            </div>
            <div>
              We help homeowners and tenants find an easy solution for clearing
              out unwanted junk. We're not the cable guys – we know you don't
              want to wait around all day, so we show up on schedule and get the
              job done quickly.
            </div>
            <div>
              You can feel great when you hire Point Blank because we get the
              job done quickly and neatly. Our customers love Point Blank
              because we provide the highest level of professionalism and
              fantastic customer service. You get a no-hassle experience with
              upfront pricing, easy scheduling, and fast service. Schedule a
              no-obligation appointment, and when you say the word we’ll haul
              away the junk and sweep everything clean.
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
                You Need Experienced Local Movers – Hire Us
              </h3>
              <div>
                At Point Blank Moving, we are a veteran led team with 20+ years
                of logistics experience covering all areas of Kansas City -
                Missouri and/or Kansas.
              </div>
            </div>
            <div className="content--loc--generic">
              <h2 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
                Types of Junk Removal Services Provided
              </h2>
              <div>
                Having a clean and organized home makes everyone that comes into
                the home or space happier. Not that we believe you should clean
                up your junk because of others, but rather we know it will make
                you feel better in the long run. Let Point Blank haul off your
                unwanted waste, junk, and unwanted stuff:
                <ul style={{ marginTop: 10, marginLeft: 30 }}>
                  <li>Hauling Services by the dumpster load.</li>
                  <li>
                    House cleanouts for your upcoming move, spring cleanings or
                    remodeling projects.
                  </li>
                  <li>Trash Removal or overflowing yard waste</li>
                  <li>
                    Property cleanouts for real estate agents, landlords, or
                    inherited properties
                  </li>
                </ul>
                <br />
                Our junk removal services include but are not limited to:
                <ul style={{ marginTop: 10, marginLeft: 30 }}>
                  <li>Air Conditioning Disposal</li>
                  <li>Dishwashers</li>
                  <li>Trash compactors</li>
                  <li>DIY construction debris</li>
                  <li>Estate cleanouts</li>
                  <li>Refrigerator/Freezer disposal</li>
                  <li>Foreclosure cleanout</li>
                  <li>Furniture removal</li>
                  <li>Garbage removal</li>
                  <li>Hot Tub removal</li>
                  <li>House Clean Outs</li>
                  <li>Junk Hauling Services</li>
                  <li>Lawn Mower disposal</li>
                  <li>Landscaping equipment disposal</li>
                  <li>Property Cleanout Services</li>
                  <li>Scrap Metal Recycling</li>
                  <li>Stove Removal</li>
                  <li>Oven Removal</li>
                  <li>Microwave Disposal</li>
                  <li>TV Disposal</li>
                  <li>Water heater Disposal</li>
                  <li>Yard Waste Disposal</li>
                  <li>And anything else you can think of</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JunkRemoval;
